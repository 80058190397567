import React from "react"
import styled from "@emotion/styled"
import { keyframes } from "@emotion/react"
import { CardWrapper } from "../../card"
import Typography from "../../typography"
import { BigPillButton } from "../../button"
import colors from "../../../styles/colors"
import {
  BORDER_RADIUS_LARGE,
  DesktopMediaQuery,
  MobileMediaQuery,
} from "../../../styles/constants"
import ChatBubble from "../../icons/chatBubble"
import DemoContext from "../../../contexts/demo"

const Button = styled(BigPillButton)`
  margin-top: 4rem;
  width: 330px;
  padding: 2rem 5rem;
  ${MobileMediaQuery} {
    margin: 4rem auto;
    padding: 22px 12px;
    width: 280px !important;
  }
`

const Title = styled(Typography)`
  ${DesktopMediaQuery} {
    max-width: 380px;
  }
`

const ContentWrapper = styled.div`
  flex: 1;
  padding: 5rem 5rem 5rem 3rem;
  padding-bottom: 0;
  ${MobileMediaQuery} {
    padding: 2.4rem;
    padding-bottom: 0;
  }
`

const MoveKeyframes = keyframes`
from {
  transform: translateX(calc(100vw + 50%));
}
to {
  transform: translateX(calc(0vw - 100%));
}
`
const ChatBubbleWrapper = styled.div`
  width: 500px;
  height: 220px;
  z-index: 1;
  transform: translateX(calc(100vw + 50%));
  animation: ${MoveKeyframes} 30s infinite linear;
  animation-delay: ${p => p.delay}s;
  position: absolute;
`
const ChatBubbleIconWrapper = styled.div`
  position: absolute;
  ${MobileMediaQuery} {
    svg {
      width: 300px !important;
      height: 120px !important;
    }
  }
`

const ChatBubbleTextWrapper = styled.div`
  width: 500px;
  height: 220px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  padding: 50px;

  ${MobileMediaQuery} {
    width: 300px;
    height: 120px;
  }
`

const ChatBubbleItem = ({ symptom, hexColor, delay }) => {
  return (
    <ChatBubbleWrapper delay={delay}>
      <ChatBubbleIconWrapper>
        <ChatBubbleTextWrapper>"{symptom}"</ChatBubbleTextWrapper>
        <ChatBubble style={{ color: hexColor }} />
      </ChatBubbleIconWrapper>
    </ChatBubbleWrapper>
  )
}

const WrapperDiv = styled.div`
  display: flex;
  height: 220px;
  overflow: hidden;
  width: 100vw;
  position: relative;
  ${MobileMediaQuery} {
    height: 150px;
    padding-top: 20px;    
  }
}
`

const Card = styled(CardWrapper)`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 1400px;

  border-radius: ${BORDER_RADIUS_LARGE}px;
  ${DesktopMediaQuery} {
    height: 750px;
  }

  ${MobileMediaQuery} {
    flex-direction: column;
  }
`

const ChatBubbleList = ({ hexColor, productName, questions }) => {
  const chatBubbles = questions.map((symptom, index) => (
    <ChatBubbleItem
      key={index}
      symptom={symptom}
      hexColor={hexColor}
      delay={index * 10 - 20}
    />
  ))
  const { open: openDemo } = React.useContext(DemoContext)

  return (
    <>
      <WrapperDiv>{chatBubbles}</WrapperDiv>
      <Card variant="white">
        <ContentWrapper>
          <Title
            variant="h1"
            style={{
              color: hexColor,
              maxWidth: "1200px",
              marginBottom: "20px",
            }}
            as="h1"
          >
            Do you know what's frustrating your customers? We do.
          </Title>
          <div style={{ maxWidth: "800px" }}>
            <Typography variant="bodyLarge" style={{ color: colors.black }}>
              These are some of the most common questions about the{" "}
              {productName} from all over the internet. Mavenoid collect data,
              organizes it, and generates a truly relevant resource, almost in
              real-time. Building a support base like this traditionally takes
              weeks, even months, of manual work.
            </Typography>
          </div>
          <Button
            style={{ textAlign: "center", width: "380px" }}
            onClick={openDemo}
          >
            Book a personal demo
          </Button>
        </ContentWrapper>
      </Card>
    </>
  )
}

export default ChatBubbleList
