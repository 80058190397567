import React from "react"
import styled from "@emotion/styled"
import Img from "gatsby-image/withIEPolyfill"
import { CardWrapper } from "../../card"
import ThickArrow from "../../icons/thickArrow"
import { List, ListItem } from "../../list"
import Typography, { typographyStyles } from "../../typography"
import { BigPillButton } from "../../button"
import colors from "../../../styles/colors"
import {
  BORDER_RADIUS_LARGE,
  DesktopMediaQuery,
  MobileMediaQuery,
} from "../../../styles/constants"
import Link from "../../link"
import { trackEvent } from "../../../utils/trackEvent"
import { ProductAssistantPersonalizedLandingPage } from "../../illustrations/productAssistantPersonalizedLandingPages"

const Card = styled(CardWrapper)`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 1400px;
  border-radius: ${BORDER_RADIUS_LARGE}px;
  ${DesktopMediaQuery} {
    height: 750px;
  }

  ${MobileMediaQuery} {
    flex-direction: column;
  }
`

const Button = styled(BigPillButton)`
  margin-top: 4rem;
  width: 330px;
  padding: 2rem 5rem;
  ${MobileMediaQuery} {
    margin: 4rem auto;
    padding: 22px 12px;
    width: 280px !important;
  }
`

const Title = styled(Typography)`
  ${DesktopMediaQuery} {
    max-width: 380px;
  }
`

const ContentWrapper = styled.div`
  flex: 1;
  padding: 5rem 5rem 5rem 3rem;

  ${MobileMediaQuery} {
    padding: 2.4rem;
  }
`

const ListItemArrow = styled(ListItem)`
  ${MobileMediaQuery} {
    ${typographyStyles.bodySmall}
  }
  svg {
    ${MobileMediaQuery} {
      top: 2px;
    }
  }
`

const ImageAbsoluteWrapper = styled.div`
  flex: 1;
  position: relative;

  ${DesktopMediaQuery} {
    height: 100%;
    display: flex;
    align-items: center;
  }

  ${MobileMediaQuery} {
    width: 100%;
  }
`

const ImageWrapper = styled.div`
  ${DesktopMediaQuery} {
    // position: absolute;
    right: 0;
  }

  ${MobileMediaQuery} {
    width: 100%;
    margin-top: 1rem;
  }
`

const ProductAssistantSVG = styled(ProductAssistantPersonalizedLandingPage)`
  position: absolute;
  width: 351px;
  height: 539px;
  right: 40px;
  top: 90px;

  ${MobileMediaQuery} {
    width: 55%;
    right: 12%;
    top: 11%;
    height: initial;
  }
`

const ArrowListItem = ({ children, hexColor }) => (
  <ListItemArrow icon={<ThickArrow color={hexColor} />}>
    {children}
  </ListItemArrow>
)

const RoundImage = styled(Img)`
  /* Small desktop improvement */
  height: 100%;
  position: none;
  /* Safari fix */
  img {
    border-radius: 6;
  }
  ${MobileMediaQuery} {
    height: 900px;
  }
  @media (max-width: 768px) {
    height: 700px;
  }
  @media (max-width: 668px) {
    height: 600px;
  }
  @media (max-width: 568px) {
    height: 500px;
  }
  @media (max-width: 468px) {
    height: 400px;
  }
  @media (max-width: 368px) {
    height: 300px;
  }
`

const CardProductAssistantPersonalizedLandingPage = ({
  hexColor,
  productName,
  screenshot,
  companyName,
}) => {
  const valueAdded = [
    `Majority of ${productName}’s technical support questions are troubleshooting in nature`,
    "Those support questions stem from 7 general symptoms which cause root issues",
    "By automating those requests, 30% could be deflected through self-service",
  ]
  return (
    <Card variant="lightgrey">
      <ImageAbsoluteWrapper>
        <ImageWrapper>
          <RoundImage
            fluid={[screenshot.childImageSharp.fluid]}
            style={{ position: "static" }}
            objectFit="cover"
            objectPosition="100% 100%"
            alt="Mavenoid Troubleshooter in front of a quadri-copter"
          />
          <ProductAssistantSVG fill={hexColor} />
        </ImageWrapper>
      </ImageAbsoluteWrapper>
      <ContentWrapper>
        <Title variant="h3" style={{ color: hexColor }} as="h3">
          Let us show you what Mavenoid can do for {companyName}.
        </Title>
        <List
          style={{
            marginTop: "4rem",
            fontSize: "20px",
            color: colors.darkgrey,
          }}
        >
          {valueAdded.map(valueAdd => (
            <ArrowListItem hexColor={hexColor}>{valueAdd}</ArrowListItem>
          ))}
        </List>
        <Button
          as={Link}
          style={{ textAlign: "center", width: "380px" }}
          to="https://app.mavenoid.com/signup/"
          onClick={() => {
            trackEvent({
              category: "sign_up_redirect",
              label: "homepage product assistant list build it now",
              siteSection: "homepage_product_assistant",
            })
          }}
        >
          Book a personal demo
        </Button>
      </ContentWrapper>
    </Card>
  )
}

export default CardProductAssistantPersonalizedLandingPage
