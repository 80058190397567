// @ts-check
import React from "react"

const ChatBubble = (/** @type {React.SVGAttributes} */ props) => (
  <svg
    width="500"
    height="220"
    viewBox="0 0 500 220"
    fill="currentColor"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M110.996 0C50.2448 0 0.996094 49.2487 0.996094 110C0.996094 170.751 50.2448 220 110.996 220H389.363C412.012 220 433.062 213.155 450.557 201.421L490.045 217.108L475.381 178.57C490.391 159.766 499.363 135.931 499.363 110C499.363 49.2487 450.115 0 389.363 0H110.996Z"
    />
  </svg>
)

export default ChatBubble
