import React from "react"
import styled from "@emotion/styled"
import Img from "gatsby-image/withIEPolyfill"
import { CardWrapper } from "../../card"
import Typography from "../../typography"
import { BigPillButton } from "../../button"
import colors, { foregroundColors, hoverColors } from "../../../styles/colors"
import {
  BORDER_RADIUS_LARGE,
  DesktopMediaQuery,
  MobileMediaQuery,
} from "../../../styles/constants"
import { trackEvent } from "../../../utils/trackEvent"
import Ellipse from "../../icons/ellipse"
import mavenoidHelpCenter from "../../../utils/mavenoidHelpCenter"

const Card = styled(CardWrapper)`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 1400px;
  overflow: hidden;

  border-radius: ${BORDER_RADIUS_LARGE}px;
  ${DesktopMediaQuery} {
    height: 750px;
  }

  ${MobileMediaQuery} {
    flex-direction: column;
  }
`

const Button = styled(BigPillButton)`
  margin-top: 4rem;
  width: 330px;
  padding: 2rem 5rem;
  background: ${colors["primary"]};
  color: ${foregroundColors["primary"]};
  :hover {
    background-color: ${p => hoverColors["primary"]};
    border-color: ${p => hoverColors["primary"]};
    color: ${p => foregroundColors["primary"]};
  }
  ${MobileMediaQuery} {
    margin: 4rem auto;
    padding: 22px 12px;
    width: 280px !important;
  }
`

const RoundImage = styled(Img)`
  /* Small desktop improvement */
  height: 100%;
  width: 400px;

  /* Safari fix */
  img {
    border-radius: 10;
  }

  /* Aspect-ratio fix - this should be handled by gatsby-image but isn't */
  @media (max-width: 768px) {
    & > div {
      padding-bottom: 82% !important;
    }
  }
`

const Title = styled(Typography)`
  ${DesktopMediaQuery} {
    max-width: 380px;
  }
`

const ContentWrapper = styled.div`
  flex: 1;
  padding: 5rem 0rem 5rem 3rem;
  z-index: 1;
  position: relative;

  ${MobileMediaQuery} {
    padding: 2.4rem;
  }
`

const ImageAbsoluteWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  ${DesktopMediaQuery} {
    height: 100%;
    display: flex;
    align-items: center;
  }

  ${MobileMediaQuery} {
    width: 100%;
    position: absolute;
    height: 260px;
    bottom: 0px;
  }
`

const EllipseWrapper = styled.div`
  position: absolute;
  left: 20%;
  color: #f0f0f0;

  ${MobileMediaQuery} {
    display: none;
  }
`

const PersonalizedLandingPageHeader = ({
  hexColor,
  productName,
  customerName,
  productImage,
  productId,
  clientId,
  companyName,
}) => {
  const openTroubleshooter = () => {
    window.localStorage.removeItem("mavenoid-embedded-user")
    mavenoidHelpCenter(clientId, "https://app.mavenoid.com")
    window.mavenoid = window.mavenoid || []
    window.mavenoid.push({
      productId: productId,
    })
  }
  return (
    <Card variant="white">
      <ContentWrapper>
        <Title
          variant="h1"
          style={{ color: hexColor, maxWidth: "1000px", marginBottom: "20px" }}
          as="h1"
        >
          Glad you're here{customerName ? `, ${customerName}` : "!"}
        </Title>
        <div style={{ marginTop: "20px" }}>
          <Typography variant="bodyLarge" style={{ color: colors.black }}>
            In just a few minutes, our AI analyzed thousands of real customer
            issues and created a support bot for {productName}.
          </Typography>
          <br />
          <br />
          <Typography
            variant="bodyLarge"
            style={{ color: colors.black, marginTop: "20px" }}
          >
            This bot is entirely autogenerated—no humans have touched it. With a
            {` ${companyName}`} and Mavenoid partnership, we can make it even
            better. It could solve up to 30% of your support requests.
          </Typography>
        </div>
        <Button
          style={{ textAlign: "center", width: "380px" }}
          onClick={() => {
            openTroubleshooter()
            trackEvent({
              category: "personalized_landing_page",
              label: `Personalized page for open assistant ${companyName}`,
              siteSection: "personalized_landingpage_product_assistant",
            })
          }}
        >
          Try AI-made support for {productName}
        </Button>
      </ContentWrapper>
      <EllipseWrapper>
        <Ellipse width={700} height={700} />
      </EllipseWrapper>
      <ImageAbsoluteWrapper>
        <RoundImage
          fluid={[productImage.childImageSharp.fluid]}
          objectFit="contain"
          objectPosition="50% 50%"
          alt="Product image"
        />
      </ImageAbsoluteWrapper>
    </Card>
  )
}

export default PersonalizedLandingPageHeader
