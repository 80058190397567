import styled from "@emotion/styled"
import React from "react"
import colors, { hoverColors } from "../styles/colors"
import mavenoidHelpCenter from "../utils/mavenoidHelpCenter"
import { GlyphLogo } from "./icons/logos"

const ButtonWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;

  width: 56px;
  height: 56px;
  border-radius: 1000px;
  background-color: ${colors.primary};
  color: ${colors.white};
  padding: 10px;

  cursor: pointer;

  box-shadow: 0 0 18px -8px ${colors.darkgrey};

  &:hover {
    background-color: ${hoverColors.primary};
  }
`

const MavenoidHelpButton = ({ productId, clientId }) => {
  const openTroubleshooter = () => {
    window.localStorage.removeItem("mavenoid-embedded-user")
    mavenoidHelpCenter(clientId, "https://app.mavenoid.com")
    window.mavenoid.push({
      productId: productId,
    })
  }
  return (
    <ButtonWrapper onClick={openTroubleshooter}>
      <GlyphLogo />
    </ButtonWrapper>
  )
}

export default MavenoidHelpButton
