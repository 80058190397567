// @ts-check
import styled from "@emotion/styled"
// import { graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import Card from "../components/card"
import Link from "../components/link"
import colors, { hoverColors } from "../styles/colors"
import CardPartners from "../components/cards/cardPartners"
import CardSelfServiceLiveSupport from "../components/cards/tools/cardSelfServiceLiveSupport"
import { MobileMediaQuery } from "../styles/constants"
import { typographyStyles } from "../components/typography"
import CardTicketFlow from "../components/cards/tools/cardTicketFlow"
import { trackEvent } from "../utils/trackEvent"
import CardProductAssistantPersonalizedLandingPage from "../components/cards/personalizedLandingPages/cardProductAssistantPersonalizedLandingPage"
import PersonalizedLandingPageHeader from "../components/cards/personalizedLandingPages/personalizedLandingPageHeader"
import ChatBubbleList from "../components/cards/personalizedLandingPages/ChatbubbleList"
import SebastianPfahler from "../components/cards/testimonials/testimonialSebastian"
import mavenoidHelpCenter from "../utils/mavenoidHelpCenter"
import MavenoidHelpButton from "../components/mavenoidHelpButton"
import DemoContext from "../contexts/demo"
import { HoverButton } from "../components/button"

const Pitch = styled.div`
  ${typographyStyles.h6}
  max-width: 800px;
  line-height: 34px;

  ${MobileMediaQuery} {
    ${typographyStyles.bodySmall}
  }
`

const HoverLink = styled(Link)`
  display: inline-block;
  border-radius: 4px;
  padding: 0.2rem 0.3rem;
  color: ${colors.white};
  margin: 0 3px;
  background-color: ${p => colors[p.variant || "primary"]};
  text-decoration: none;

  &:hover {
    background-color: ${p => hoverColors[p.variant || "primary"]};
  }
`

const HelpButtonWrapper = styled.div`
  position: absolute;
  z-index: 1000;
`
const Wrapper = styled.div`
  max-width: 100%;
  overflow-x: hidden;
`

const TryItFreeButton = styled(HoverButton)`
  font-weight: 600;
  font-size: 14px !important;
  margin-left: 14px;
  border-radius: 74px;
  height: 44px;
  width: 132px;
  line-height: 24px !important;
  align-items: initial;
`

const DemoButton = styled(TryItFreeButton)`
  background-color: transparent;
  color: ${p => colors[p.variant || "primary"]};
  border: 2px solid ${p => colors[p.variant || "primary"]};
  outline: none;
  line-height: 23px !important;

  &:hover {
    background-color: ${p => colors[p.variant || "primary"]};
    border-color: ${p => colors[p.variant || "primary"]};
    color: ${colors.white};
  }
`

const extractQuestionsFromModel = model => {
  if (!model) {
    return []
  }
  const { operations } = model.supportModels[0]
  return operations
    .filter(node => {
      if (node.type === "CheckflowSymptom" && node.id.includes("problem")) {
        return true
      }
      return false
    })
    .map(node => {
      return node.prompt
    })
}

const PersonalizedCustomerLandingPage = props => {
  const [customerName, setCustomerName] = useState("")
  const {
    location,
    pageContext: {
      customerInfo: {
        productName,
        hexColor,
        productImage,
        screenshot,
        productId,
        clientId,
        questions,
        companyName,
        model,
      },
    },
  } = props
  const openTroubleshooter = React.useCallback(() => {
    window.localStorage.removeItem("mavenoid-embedded-user")
    mavenoidHelpCenter(clientId, "https://app.mavenoid.com")
    if (window) {
      window.mavenoid = window.mavenoid || []
      window.mavenoid.push({
        productId: productId,
      })
    }
  }, [clientId, productId])

  useEffect(() => {
    if (window.innerWidth >= 500) {
      openTroubleshooter()
    }
  }, [openTroubleshooter])
  useEffect(() => {
    setCustomerName(new URLSearchParams(location.search).get("c"))
  }, [location.search])
  const { open: openDemo } = React.useContext(DemoContext)
  const questionsFromModel = extractQuestionsFromModel(model)
  const newQuestions =
    questionsFromModel.length > 0 ? questionsFromModel : questions
  return (
    <Wrapper>
      <HelpButtonWrapper>
        <MavenoidHelpButton productId={productId} clientId={clientId} />
      </HelpButtonWrapper>
      <Layout
        bg="white"
        demoButton={
          <DemoButton onClick={openDemo} variant={"primary"} size="s">
            Get a demo
          </DemoButton>
        }
      >
        <PersonalizedLandingPageHeader
          hexColor={hexColor}
          productName={productName}
          customerName={customerName}
          productImage={productImage}
          productId={productId}
          clientId={clientId}
          companyName={companyName}
        />
        <CardProductAssistantPersonalizedLandingPage
          hexColor={hexColor}
          productName={productName}
          screenshot={screenshot}
          companyName={companyName}
        />
        <ChatBubbleList
          hexColor={hexColor}
          productName={productName}
          questions={newQuestions}
        />
        <CardPartners variant="white" />
        <CardSelfServiceLiveSupport />
        <CardTicketFlow style={{ marginTop: "6rem" }} />
        <Card variant="white" center noMinHeight>
          <Pitch>
            Mavenoid’s{" "}
            <HoverLink
              variant="lightpurple"
              to="/product/self-service/"
              onClick={() => {
                trackEvent({
                  category: "view_item",
                  label: "homepage no chit chat self service",
                  siteSection: "homepage_no_chit_chat",
                })
              }}
            >
              self-service
            </HoverLink>{" "}
            solves repetitive requests before they reach your human support
            team. Humans will deal only with the tickets that really require
            their expertise, through Mavenoid{" "}
            <HoverLink
              to="/product/live-support/"
              onClick={() => {
                trackEvent({
                  category: "view_item",
                  label: "homepage no chit chat live support",
                  siteSection: "homepage_no_chit_chat",
                })
              }}
            >
              live support
            </HoverLink>
            .
          </Pitch>
        </Card>
        <SebastianPfahler />
      </Layout>
    </Wrapper>
  )
}

export default PersonalizedCustomerLandingPage
