import React from "react"
import Img from "gatsby-image/withIEPolyfill"
import { graphql, useStaticQuery } from "gatsby"

import styled from "@emotion/styled"
import Typography from "../../typography"
import colors from "../../../styles/colors"
import Link from "../../link"
import { DoubleCard } from "../../card"
import { DesktopMediaQuery, MobileMediaQuery } from "../../../styles/constants"
import { ButtonWithArrow } from "../../button"
import { trackEvent } from "../../../utils/trackEvent"

const TextPaddingWrapper = styled.div`
  flex: 1;
`

const TextWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${p => colors[p.variant || "primary"]};
  padding: 80px;
  color: ${colors.white};

  ${MobileMediaQuery} {
    padding: 20px;
    border-radius: 0 0 24px 24px;
    min-height: 360px;
  }

  ${DesktopMediaQuery} {
    border-radius: ${p =>
      p.isReverse ? "4px 24px 24px 4px" : "24px 4px 4px 24px"};
  }
`

const ImageWrapper = styled.div`
  flex: 1;
  width: 100%;
  background-color: ${colors.lightgrey};
  overflow: hidden;

  ${MobileMediaQuery} {
    border-radius: 24px 24px 0 0;
    margin-bottom: 0;
    height: 100%;
  }

  ${DesktopMediaQuery} {
    border-radius: ${p =>
      p.isReverse ? "4px 24px 24px 4px" : "24px 4px 4px 24px"};
  }
`

const Wrapper = styled(DoubleCard)`
  ${DesktopMediaQuery} {
    height: 600px;
    ${TextPaddingWrapper}:first-of-type, ${ImageWrapper}:first-of-type {
      margin-right: 4px;
    }
  }

  ${MobileMediaQuery} {
    flex-direction: ${p => (p.isReverseMobile ? "column-reverse" : "column")};
  }

  ${MobileMediaQuery} {
    padding-top: 0px;
  }
`

const BottomContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
`

const Image = ({ fluid }) => {
  return <Img fluid={fluid} style={{ height: "100%" }} />
}

const CardSelfServiceLiveSupport = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "tools/drone_square.png" }) {
        childImageSharp {
          fluid(
            maxWidth: 1920
            quality: 80
            traceSVG: { background: "#DDDDDE", color: "#232322" }
          ) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      troubleshooterChainsaw: file(
        relativePath: { eq: "tools/troubleshooter-chainsaw.png" }
      ) {
        childImageSharp {
          fluid(
            maxWidth: 1920
            quality: 80
            traceSVG: { background: "#DDDDDE", color: "#232322" }
          ) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <>
      <Wrapper style={{ marginBottom: "40px" }} isReverseMobile>
        <TextPaddingWrapper>
          <TextWrapper
            variant="lightpurple"
            style={{ backgroundColor: colors.lightpurple }}
          >
            <Typography variant="h2">
              AI-guided <br /> self-service
            </Typography>
            <BottomContent>
              <Typography variant="bodyLarge">
                Troubleshoot tough problems, give expert advice, and answer
                time-consuming questions—without the need for your human team to
                step in.
              </Typography>
              <ButtonWithArrow
                style={{ marginTop: "2rem" }}
                variant="lightpurple"
                as={Link}
                to="/product/self-service/"
                ghost
                onClick={() => {
                  trackEvent({
                    category: "view_item",
                    label: "homepage self service card learn more",
                    siteSection: "homepage_self_service_card",
                  })
                }}
              >
                Learn more
              </ButtonWithArrow>
            </BottomContent>
          </TextWrapper>
        </TextPaddingWrapper>
        <ImageWrapper isReverse>
          <Image fluid={data.troubleshooterChainsaw.childImageSharp.fluid} />
        </ImageWrapper>
      </Wrapper>

      <Wrapper>
        <ImageWrapper>
          <Image fluid={data.file.childImageSharp.fluid} />
        </ImageWrapper>
        <TextPaddingWrapper>
          <TextWrapper isReverse variant="primary" style={{ marginRight: 0 }}>
            <Typography variant="h2">
              Agent-assisted <br /> video support
            </Typography>
            <BottomContent>
              <Typography variant="bodyLarge">
                Help customers solve complex and unique problems without the
                need for physical visits or expensive product returns.
              </Typography>
              <ButtonWithArrow
                style={{ marginTop: "2rem" }}
                as={Link}
                to="/product/live-support/"
                ghost
                onClick={() => {
                  trackEvent({
                    category: "view_item",
                    label: "homepage live support card learn more",
                    siteSection: "homepage_live_support_card",
                  })
                }}
              >
                Learn more
              </ButtonWithArrow>
            </BottomContent>
          </TextWrapper>
        </TextPaddingWrapper>
      </Wrapper>
    </>
  )
}

export default CardSelfServiceLiveSupport
